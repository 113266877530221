import React, { useState, useEffect, useRef } from "react"
import { PageRenderer, graphql, navigate } from "gatsby"
import he from "he"
import PropTypes from "prop-types"

import Seo from "../components/seo"
import RenderBlock from "../utils/render-block"
import Link from "../utils/link"
import Fade from "react-reveal/Fade"

import ImageComponent from "../components/Common/ImageComponent"
import WingspanLogo from "../assets/images/Vectors/WingspanFullMark.svg"
import CloseIcon from "../assets/images/Vectors/times.svg"
import StandAloneLogo from "../assets/images/Vectors/Shape.svg"
import DownloadIcon from "../assets/images/Vectors/Download.svg"
import ArrowIcon from "../assets/images/Vectors/np_arrow-right_888647_FFFFFF-1.svg"
import "./CareersPopupTemplate.scss"

const CareersPopupTemplate = ({ data }) => {
  const {
    applyLinkUrl,
  } = data.wpCareer.acf
  const { title, content } = data.wpCareer

  const careerOverlay = useRef(null)

  const [path1, setPath1] = useState()

  useEffect(() => {
    typeof window !== `undefined`
      ? setPath1(window.location.pathname)
      : setPath1("")
  }, [path1])

  const navigateBack = (e) => {
    e.preventDefault()
    const position = parseInt(sessionStorage.getItem("positionInitial"))
    sessionStorage.removeItem("positionInitial")
    if (position) {
      sessionStorage.setItem("position", position)
    }
    navigate(sessionStorage.getItem("prevLocation"))
  }

  return (
    <div>
      <PageRenderer key={"/careers"} location={{ pathname: "/careers" }} />
      <div
        ref={careerOverlay}
        onLoad={() => {
          careerOverlay.current.focus()
        }}
        className="overlay-page"
        tabIndex="0"
      >
        <div className="content">
          <Fade>
            <div className="content__header content__container">
              <Link to="/" title="Wingspan Capital" className="header__logo">
                <img src={WingspanLogo} />
              </Link>

              <div className="content__closeTrigger">
                <button
                  onClick={(e) => {
                    navigateBack(e)
                  }}
                >
                  <img
                    className="content__close"
                    src={CloseIcon}
                    alt="close button"
                  />
                </button>
              </div>
            </div>
          </Fade>
          <div className="content__container">
            <div className="career">
              <Fade>
                <div className="career__header">
                  <div className="career__headerWrapper">
                    <h3 className="career__heading">{title}</h3>
                  </div>
                </div>
              </Fade>
              <div className="popupContent">
                <div className="popup">
                  <div className="popup__download">
                    { applyLinkUrl &&
                      <Fade bottom>
                        <div className="popup__downloadWrapper">
                          <a
                            href={applyLinkUrl}
                            target="_blank"
                            rel="noopener noreferer"
                            className="popup__heading"
                          >
                            APPLY FOR JOB
                          </a>
                        </div>
                      </Fade>
                    }
                  </div>
                  <div className="popup__content">
                    <div className="popup__contentWrapper">
                      {data.wpCareer && <Seo title={he.decode(data.wpCareer.seo.title)} description={data.wpCareer.seo.metaDesc} />}

                      <div className="popup__details" dangerouslySetInnerHTML={{__html: content}} />

                      <Fade>
                        <div className="popup__buttonContainer">
                          <div className="popup__buttonContainerWrapper">
                            <div>
                              <button
                                onClick={(e) => {
                                  navigateBack(e)
                                }}
                                className="popupContent__closeTrigger"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career__footer">
                <div className="career__emptyContent"></div>
                <Fade>
                  <div className="career__footerWrapper">
                    <Link to="/">
                      <img
                        className="career__standaloneLogo"
                        src={StandAloneLogo}
                        alt="standaloneLogo"
                      />
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    wpCareer(id: { eq: $id }) {
      slug
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        fieldGroupName
        applyLinkUrl
      }
    }
  }
`

CareersPopupTemplate.propTypes = {
  data: PropTypes.object,
  applyLinkUrl: PropTypes.string,
  title: PropTypes.string,
}

export default CareersPopupTemplate
